.carousel {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    transition: opacity 1.5s ease-in-out;
}

.carousel.active {
    opacity: 1;
    transition: opacity 1.5s ease-in-out;
}