.image {
    z-index: 2;
}
.image:hover {
    scale: 3;
    z-index: 100;
}
.image h5 {
    color:  'rgb(65, 65, 65)';
}
.image:hover h5 {
    display: none;
    visibility: hidden;
}