.btn {
    background: rgba(0,0,0,0);
    border: none;
    outline: none;
    margin: 0;
    cursor: pointer;
    padding-inline-end: 0;
    padding-inline-start: 0;
    text-align: left;
    opacity: 1;
    transition: opacity 200ms ease;
  }

  .touched {
    opacity: 0.3;
  }