.sticky {
	position: fixed;
	z-index: 100;
	background-color: white;
	width: 100%;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.125);
}

.home {
	position: absolute;
	width: 100%;
	top: 0;
}
