@font-face {
  font-family: Open Sans;
  src: url(./../../../assets/Fonts/OpenSans-Regular.ttf);
}


.button {
	font-size: 13px;
	font-family: 'Open Sans';
	letter-spacing: 0.9275px;
	line-height: 32px;
	text-align: center;
	transition: color 200ms ease-in-out;
	padding-left: 18px;
	padding-right: 18px;
}

.button::after {
	content: '';
	z-index: -1;
	position: absolute;
	left: 0;
	top: auto;
	bottom: 0;
	width: 100%;
	height: 100%;
	transition: height 200ms ease-in-out, top 0ms, bottom 0ms;
}

.button:hover::after {
	height: 0%;
	top: 0;
	bottom: auto;
}

.button::before {
	content: '';
	z-index: -1;
	position: absolute;
	left: 0;
	top: 0;
	bottom: auto;
	width: 100%;
	height: 0%;
	transition: height 200ms ease-in-out, top 0ms, bottom 0ms;
}

.button:hover::before {
	height: 100%;
	top: auto;
	bottom: 0;
}

.black::before {
	background-color: white;
}

.black::after {
	background-color: black;
}

.black {
	border: 2px solid black;
	background-color: rgba(0, 0, 0, 0);
	color: white;
}
.black:hover {
	border: 2px solid black;
	color: #414141;
	cursor: pointer;
}

.blackFilled::before {
	background-color: white;
}

.blackFilled::after {
	background-color: black;
}

.blackFilled {
	border: 2px solid black;
	background-color: rgba(0, 0, 0, 1);
	color: white;
}
.blackFilled:hover {
	border: 2px solid black;
	color: #414141;
	cursor: pointer;
}

.pinkWhite {
	transition: border 100ms ease-in-out, background 100ms ease-in-out, color 100ms ease-in-out;
	background-color: #f2cfd1;
	border: 2px solid #f2cfd1;
	color: white;
	cursor: pointer;
}
.pinkWhite:hover {
	background-color: #cfb4b5;
	border: 2px solid #cfb4b5;
	color: white;
	cursor: pointer;
}

.greenWhite {
	transition: border 100ms ease-in-out, background 100ms ease-in-out, color 100ms ease-in-out;
	background-color: #B9DCBF;
	border: 2px solid #B9DCBF;
	color: #414141;
	cursor: pointer;
}
.greenWhite:hover {
	background-color: #8ec197;
	border: 2px solid #8ec197;
	color: #414141;
	cursor: pointer;
}

.greenBlack {
	background-color: white;
	border: 2px solid #B9DCBF;
	color: #414141;
	transition: border 100ms ease-in-out, background 100ms ease-in-out, color 100ms ease-in-out;
}
.greenBlack:hover {
	background-color: #B9DCBF;
	color: white;
	cursor: pointer;
}

.whiteGreen {
	background-color: white;
	border: 2px solid #B9DCBF;
	color: #B9DCBF;
	transition: border 100ms ease-in-out, background 100ms ease-in-out, color 100ms ease-in-out;
}
.whiteGreen:hover {
	background-color: #B9DCBF;
	color: white;
	cursor: pointer;
}

.whiteOpacity::before {
	background-color: white;
}

.whiteOpacity::after {
	background-color: rgba(0, 0, 0, 0);
}

.whiteOpacity {
	background-color: rgba(0, 0, 0, 0);
	color: white;
	cursor: pointer;
	border: 2px solid white;
}
.whiteOpacity:hover {
	color: #414141;
}

.lightGreen::before {
	background-color: #B9DCBF;
}

.lightGreen {
	background-color: rgba(0, 0, 0, 0);
	color: #414141;
	cursor: pointer;
	border: 2px solid #B9DCBF;
}
.lightGreen:hover {
	color: black;
}

.blackOpacity::before {
	background-color: #414141;
}

.blackOpacity {
	background-color: rgba(0, 0, 0, 0);
	color: '#414141';
	cursor: pointer;
	border: 2px solid #414141;
}
.blackOpacity:hover {
	color: white;
}

.white::after {
	background-color: white;
}

.white::before {
	background-color: #414141;
}

.white {
	background-color: rgba(0, 0, 0, 0);
	color: #414141;
	cursor: pointer;
	transition: border 200ms ease-in-out;
	border: none;
}

.white:hover {
	color: white;
}
.blueWhite::before {
	background-color: #2773cd;
}

.blueWhite {
	background-color: #4A90E2;
	border: 2px solid #4A90E2;
	color: white;
	cursor: pointer;
}
.blueWhite:hover {
	border: 2px solid #2773cd;
}

.disabled {
	border: 2px solid rgba(52, 52, 52, 0);
	background-color: rgba(52, 52, 52, 0);
	color: white;
}
