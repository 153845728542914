.underline:hover::before {
    width: 100%;
    left: 0;
}

.underline::before {
    height: 1px;
    width: 0%;
    background-color: black;
    content: '';
    bottom: 0;
    left: 50%;
    position: absolute;
    transition: width ease-out 200ms, left ease-out 200ms;
}

.underline:hover {
    cursor: pointer;
}