@import url(https://fonts.googleapis.com/css?family=Crimson+Text);
@import url(https://fonts.googleapis.com/css?family=Crimson+Text);
.collapsible {
	box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.12);
	color: black;
	mix-blend-mode: normal;
	padding: 0px 0px 18px 0px;
	border: none;
	text-align: left;
	outline: none;
	font-size: 15px;
	overflow: overlay;
}

.openBar {
	cursor: pointer;
}

.headerFont {
	font-family: 'Crimon Text';
	margin: auto;
	letter-spacing: 2.2px;
	font-size: 36px;
	margin-top: 36px;
	margin-bottom: 8px;
	color: #414141
}

.amount {
	margin-left: 10px;
	font-size: 20px;
	letter-spacing: 2.2px;

}

.collapsible:hover > .content{
	background: white;
    transition: max-height 0.5s;
}

.active, .collapsible:hover {
	background-color: white;
	transition: background 0.2s ease-in;
}

.content {
	transition: max-height 0.5s ease-out;
	color: black;

	padding: 0 18px;
	overflow: hidden;
	background-color: white;
}

.floatRight {
	float: right;
}

.upArrow {
	border-radius: 25%;
	cursor: pointer;
	margin-top: 24px;
	margin-right: 17px;
}
.upArrow:hover {
	background-color: #cce9ce;
}
.rotateArrow {
	-webkit-transform: rotate(180deg);
	        transform: rotate(180deg);
}

.row {
	display: flex;
}

.col-12 {
	flex:0 0 100%;
	max-width:100%;
}

.col-6 {
	flex:0 0 50%;
	max-width:50%;
}

.startShopping {
	padding: 0.5em 0.7em 0.5em 0.7em;
	border-radius: 5px;
	text-decoration: none;
	cursor: pointer;
	background-color: #b9dcbf;
	color: white;
}
.startShopping:hover {
	background-color: #8ec197;
	color: white;
}

@font-face {
  font-family: Open Sans;
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf);
}

@font-face {
  font-family: Open Sans Light;
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf);
}

@font-face {
  font-family: Open Sans Italic;
  src: url(/static/media/OpenSans-Italic.c7dcce08.ttf);
}

@font-face {
  font-family: Open Sans Light Italic;
  src: url(/static/media/OpenSans-LightItalic.6943fb6f.ttf);
}



@font-face {
  font-family: Open Sans Semibold;
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf);
}

.btn {
    background: rgba(0,0,0,0);
    border: none;
    outline: none;
    margin: 0;
    cursor: pointer;
    -webkit-padding-end: 0;
            padding-inline-end: 0;
    -webkit-padding-start: 0;
            padding-inline-start: 0;
    text-align: left;
    opacity: 1;
    transition: opacity 200ms ease;
  }

  .touched {
    opacity: 0.3;
  }
@font-face {
  font-family: Open Sans;
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf);
}


.button {
	font-size: 13px;
	font-family: 'Open Sans';
	letter-spacing: 0.9275px;
	line-height: 32px;
	text-align: center;
	transition: color 200ms ease-in-out;
	padding-left: 18px;
	padding-right: 18px;
}

.button::after {
	content: '';
	z-index: -1;
	position: absolute;
	left: 0;
	top: auto;
	bottom: 0;
	width: 100%;
	height: 100%;
	transition: height 200ms ease-in-out, top 0ms, bottom 0ms;
}

.button:hover::after {
	height: 0%;
	top: 0;
	bottom: auto;
}

.button::before {
	content: '';
	z-index: -1;
	position: absolute;
	left: 0;
	top: 0;
	bottom: auto;
	width: 100%;
	height: 0%;
	transition: height 200ms ease-in-out, top 0ms, bottom 0ms;
}

.button:hover::before {
	height: 100%;
	top: auto;
	bottom: 0;
}

.black::before {
	background-color: white;
}

.black::after {
	background-color: black;
}

.black {
	border: 2px solid black;
	background-color: rgba(0, 0, 0, 0);
	color: white;
}
.black:hover {
	border: 2px solid black;
	color: #414141;
	cursor: pointer;
}

.blackFilled::before {
	background-color: white;
}

.blackFilled::after {
	background-color: black;
}

.blackFilled {
	border: 2px solid black;
	background-color: rgba(0, 0, 0, 1);
	color: white;
}
.blackFilled:hover {
	border: 2px solid black;
	color: #414141;
	cursor: pointer;
}

.pinkWhite {
	transition: border 100ms ease-in-out, background 100ms ease-in-out, color 100ms ease-in-out;
	background-color: #f2cfd1;
	border: 2px solid #f2cfd1;
	color: white;
	cursor: pointer;
}
.pinkWhite:hover {
	background-color: #cfb4b5;
	border: 2px solid #cfb4b5;
	color: white;
	cursor: pointer;
}

.greenWhite {
	transition: border 100ms ease-in-out, background 100ms ease-in-out, color 100ms ease-in-out;
	background-color: #B9DCBF;
	border: 2px solid #B9DCBF;
	color: #414141;
	cursor: pointer;
}
.greenWhite:hover {
	background-color: #8ec197;
	border: 2px solid #8ec197;
	color: #414141;
	cursor: pointer;
}

.greenBlack {
	background-color: white;
	border: 2px solid #B9DCBF;
	color: #414141;
	transition: border 100ms ease-in-out, background 100ms ease-in-out, color 100ms ease-in-out;
}
.greenBlack:hover {
	background-color: #B9DCBF;
	color: white;
	cursor: pointer;
}

.whiteGreen {
	background-color: white;
	border: 2px solid #B9DCBF;
	color: #B9DCBF;
	transition: border 100ms ease-in-out, background 100ms ease-in-out, color 100ms ease-in-out;
}
.whiteGreen:hover {
	background-color: #B9DCBF;
	color: white;
	cursor: pointer;
}

.whiteOpacity::before {
	background-color: white;
}

.whiteOpacity::after {
	background-color: rgba(0, 0, 0, 0);
}

.whiteOpacity {
	background-color: rgba(0, 0, 0, 0);
	color: white;
	cursor: pointer;
	border: 2px solid white;
}
.whiteOpacity:hover {
	color: #414141;
}

.lightGreen::before {
	background-color: #B9DCBF;
}

.lightGreen {
	background-color: rgba(0, 0, 0, 0);
	color: #414141;
	cursor: pointer;
	border: 2px solid #B9DCBF;
}
.lightGreen:hover {
	color: black;
}

.blackOpacity::before {
	background-color: #414141;
}

.blackOpacity {
	background-color: rgba(0, 0, 0, 0);
	color: '#414141';
	cursor: pointer;
	border: 2px solid #414141;
}
.blackOpacity:hover {
	color: white;
}

.white::after {
	background-color: white;
}

.white::before {
	background-color: #414141;
}

.white {
	background-color: rgba(0, 0, 0, 0);
	color: #414141;
	cursor: pointer;
	transition: border 200ms ease-in-out;
	border: none;
}

.white:hover {
	color: white;
}
.blueWhite::before {
	background-color: #2773cd;
}

.blueWhite {
	background-color: #4A90E2;
	border: 2px solid #4A90E2;
	color: white;
	cursor: pointer;
}
.blueWhite:hover {
	border: 2px solid #2773cd;
}

.disabled {
	border: 2px solid rgba(52, 52, 52, 0);
	background-color: rgba(52, 52, 52, 0);
	color: white;
}

.bottom {
	margin-bottom: 2.5em;
	position: absolute;
	bottom: 0;
}
.left {
	left: 0;
	float: left;
}
.right {
	right: 0;
	float: right;
}

.productCardContainer {
    border: 0px solid #b6d7a8;
    padding: 20px;
    /* transition: border-width 200ms ease-in-out, padding 200ms ease-in-out; */
}

.productCardContainer:hover {
    border-width: 5px;
    padding: 15px;
    cursor: pointer;
}
.carousel {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    transition: opacity 1.5s ease-in-out;
}

.carousel.active {
    opacity: 1;
    transition: opacity 1.5s ease-in-out;
}
.tabs > div:hover::before {
    width: 90%;
}

.tabs > div::before {
    height: 1px;
    width: 0%;
    background-color: black;
    content: '';
    bottom: 0;
    position: absolute;
    transition: width ease-out 200ms;
    align-self: center;
}

.tabs .seperator{
    transition: none;
    border: none;
}

.tabs > div:hover {
    cursor: pointer;
}
a {
	color: #8ec197;
	text-decoration: none;
}

a:hover {
	color: #679870;
}

.sticky {
	position: fixed;
	z-index: 100;
	background-color: white;
	width: 100%;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.125);
}

.home {
	position: absolute;
	width: 100%;
	top: 0;
}

.underline:hover::before {
    width: 100%;
    left: 0;
}

.underline::before {
    height: 1px;
    width: 0%;
    background-color: black;
    content: '';
    bottom: 0;
    left: 50%;
    position: absolute;
    transition: width ease-out 200ms, left ease-out 200ms;
}

.underline:hover {
    cursor: pointer;
}
.border-spaced {
	background-image: linear-gradient(to right, #979797 40%, rgba(255, 255, 255, 0) 0%);
	background-position: top;
	background-size: 12px 1px;
	background-repeat: repeat-x;
	height: 1px;
}

.left {
	float: left;
	padding: 40px 10px;
}
.left.border-spaced {
	background-image: linear-gradient(to bottom, #979797 40%, rgba(255, 255, 255, 0) 0%);
	background-position: left;
	background-size: 1px 12px;
	background-repeat: repeat-y;
	height: 2000px;
}

.nieuwsbriefDingie {
    margin: 5px;
    margin-left: 20px;
    font-family: 'Open Sans';
    cursor: pointer;
}

.nieuwsbriefDingie.dingieActive {
    font-weight: bold;
}
@media screen and (max-width: 600px) {
    .TheFamily_headerText__2sngs {
        width: 90%;
    }
}

@media screen and (min-width: 600px) {
    .TheFamily_headerText__2sngs {
        width: 50%;
    }
}

.TheFamily_headerText__2sngs {
    align-self: center;
    margin-bottom: 30px;
    color: #414141;
    line-height: 32px;
    font-size: 18px;
    letter-spacing: 1.1925px;
    font-family: "Open Sans Light Italic";
}

.TheFamily_werkplekHeader__2sMEg {
    font-size: 30px;
    margin-bottom: 15px;
    font-family: 'Arno Pro';
    color: #414141;
    line-height: 42px;
    letter-spacing: 2.38875px;
    text-align: center;
}

.TheFamily_werkplekText__2oGj1 {
    line-height: 32px;
    font-size: 14px;
    font-family: 'Open Sans';
    letter-spacing: 0.9275px;
}
.imgBackground {
    background-color: rgba(65,65,65, 0);
	transition: background 0.2s;
}

.imgBackground:hover {
	background-color: rgba(126, 145, 141, 0.9);
}

.storyImage{
    align-self: center;
    margin-left: 25px;
    margin-right: 25px;
    width: 308px;
    height: 308px;
}

.storyImage:hover {
    cursor: pointer;
}

.storyImage img {
    width: 100%;
    height: 100%;
}

.storyImage .imgBackground {
    position: absolute;
    top: 0px;
    width: 308px;
    height: 308px;
    align-self: center;
}

.imgBackground p {
    transition: bottom 0.25s, opacity 0.25s;
    opacity: 0;
    font-family: Crimson Text;
    position: absolute;
    bottom: -25px;
    margin-left: 25px;
    color: white;
    font-size: 25px;
    width: 258px;
    letter-spacing: 2.2px;
}

.imgBackground:hover p {
    opacity: 1;
    bottom: 0px;
}

.image {
    z-index: 2;
}
.image:hover {
    scale: 3;
    z-index: 100;
}
.image h5 {
    color:  'rgb(65, 65, 65)';
}
.image:hover h5 {
    display: none;
    visibility: hidden;
}
ul.pagination {
    list-style-type: none;
    height: 4em;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: 'Open Sans';
    font-weight: 700;
}

ul.pagination li {
    width: 2em;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
	background-color: white;
	border: 2px solid #B9DCBF;
	color: #414141;
	transition: border 100ms ease-in-out, background 100ms ease-in-out, color 100ms ease-in-out;
}

ul.pagination li:hover {
	background-color: #B9DCBF;
	color: white;
	cursor: pointer;
}

ul.pagination li.active a {
    color: black;
}


@media screen and (max-width: 800px) {
    .container {
        flex-direction: column;
        margin-top: 20px;
    }
    .leftBlock {
        width: 100%;
    }
    .rightBlock {
        width: 100%;
    }
    .title {
        margin-top: 15px;
    }
    .deliveryContainer {
        margin-top: 40px;
    }
    .optionsContainer {
        margin-top: 40px;
    }
    .selectStyle {
        width: 100%;
    }
    .descriptionText {
        width: 90%;
    }
}


@media screen and (min-width: 800px) {
    .container {
        flex-direction: row;
        margin-top: 40px;
    }
    .leftBlock {
        width: 45%;
    }
    .rightBlock {
        width: 45%;
    }
    .title {
        margin-top: 0px;
    }
    .deliveryContainer {
        margin-top: 50px;
    }
    .optionsContainer {
        margin-top: 50px;
    }
    .selectStyle {
        width: 150px;
    }
    .buttonText {
        height: 40px;
    }
    .descriptionText {
        width: 70%;
    }
}

.container {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
}

.title {
    margin-bottom: 15pc;
}

.deliveryCheck {
    align-items: 'center';
    margin-top: 10px;
}


.selectStyle {
    height: 40px;
    font-family: 'Open Sans';
    margin-top: 5px;
    margin-right: 25px;
    padding-left: 5px;
    text-transform: capitalize;
}

.buttonsContainer {
    margin-top: 20px;
}

.buttonText {
    width: 100%;
    font-family: 'Open Sans';
    font-weight: bold;
}

.descriptionTitle {
    margin-bottom: 15px;
    font-family: 'Arno Pro',
}
#root {

}

a.link {
    text-decoration: underline;
    cursor: pointer;
}

.col {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    flex-direction: row;
}

.alignJustify {
	text-align: justify;
	text-align-last: justify;
}

.alignJustify:after {
	content: "";
	display: inline-block;
	width: 100%;
}

.mb-0 {
    margin-bottom: 0px;
}

.mb-1 {
    margin-bottom: 5px;
}

.mb-2 {
    margin-bottom: 10px;
}

.mb-3 {
    margin-bottom: 15px;
}

.mb-4 {
    margin-bottom: 20px;
}

.mb-5 {
    margin-bottom: 25px;
}

.mb-6 {
    margin-bottom: 30px;
}

.mt-0 {
    margin-top: 0px;
}

.mt-1 {
    margin-top: 5px;
}

.mt-2 {
    margin-top: 10px;
}

.mt-3 {
    margin-top: 15px;
}

.mt-4 {
    margin-top: 20px;
}

.mt-5 {
    margin-top: 25px;
}

.mt-6 {
    margin-top: 30px;
}

.justifyAround {
    justify-content: space-around;
}

.justifyBetween {
    justify-content: space-between;
}

.justifyCenter {
    justify-content: center;
}

.alignStart {
    align-items: flex-start;
}

.alignEnd {
    align-items: flex-end;
}

.alignCenter {
    align-items: center;
}

.borderBottom {
    border-bottom: 1px solid black;
}

.borderTop {
    border-top: 1px solid black;
}

.borderBottomLight {
    border-bottom: 1px solid lightgrey;
}

.borderTopLight {
    border-top: 1px solid lightgrey;
}

.largeText {
    font-family: 'Arno Pro';
}
.mediumText {
    font-family: 'Open Sans';
}
.smallText {
    font-family: 'Open Sans';
}
.tinyText {
    font-family: 'Open Sans';
}

@media (max-width: 800px) {
    .largeText {
        font-size: 24px;
    }
    .mediumText {
        font-size: 18px;
    }
    .smallText {
        font-size: 15px;
    }
    .tinyText {
        font-size: 13px;
    }
}

@media (min-width: 800px) {
    .largeText {
        font-size: 36px;
    }
    .mediumText {
        font-size: 24px;
    }
    .smallText {
        font-size: 16px;
    }
    .tinyText {
        font-size: 14px;
    }
}
@font-face {
    font-family: Arno Pro;
    src: url(/static/media/ArnoPro-Regular.b3977fac.otf);
  }

