ul.pagination {
    list-style-type: none;
    height: 4em;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: 'Open Sans';
    font-weight: 700;
}

ul.pagination li {
    width: 2em;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
	background-color: white;
	border: 2px solid #B9DCBF;
	color: #414141;
	transition: border 100ms ease-in-out, background 100ms ease-in-out, color 100ms ease-in-out;
}

ul.pagination li:hover {
	background-color: #B9DCBF;
	color: white;
	cursor: pointer;
}

ul.pagination li.active a {
    color: black;
}

