#root {

}

a.link {
    text-decoration: underline;
    cursor: pointer;
}

.col {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    flex-direction: row;
}

.alignJustify {
	text-align: justify;
	text-align-last: justify;
}

.alignJustify:after {
	content: "";
	display: inline-block;
	width: 100%;
}

.mb-0 {
    margin-bottom: 0px;
}

.mb-1 {
    margin-bottom: 5px;
}

.mb-2 {
    margin-bottom: 10px;
}

.mb-3 {
    margin-bottom: 15px;
}

.mb-4 {
    margin-bottom: 20px;
}

.mb-5 {
    margin-bottom: 25px;
}

.mb-6 {
    margin-bottom: 30px;
}

.mt-0 {
    margin-top: 0px;
}

.mt-1 {
    margin-top: 5px;
}

.mt-2 {
    margin-top: 10px;
}

.mt-3 {
    margin-top: 15px;
}

.mt-4 {
    margin-top: 20px;
}

.mt-5 {
    margin-top: 25px;
}

.mt-6 {
    margin-top: 30px;
}

.justifyAround {
    justify-content: space-around;
}

.justifyBetween {
    justify-content: space-between;
}

.justifyCenter {
    justify-content: center;
}

.alignStart {
    align-items: flex-start;
}

.alignEnd {
    align-items: flex-end;
}

.alignCenter {
    align-items: center;
}

.borderBottom {
    border-bottom: 1px solid black;
}

.borderTop {
    border-top: 1px solid black;
}

.borderBottomLight {
    border-bottom: 1px solid lightgrey;
}

.borderTopLight {
    border-top: 1px solid lightgrey;
}

.largeText {
    font-family: 'Arno Pro';
}
.mediumText {
    font-family: 'Open Sans';
}
.smallText {
    font-family: 'Open Sans';
}
.tinyText {
    font-family: 'Open Sans';
}

@media (max-width: 800px) {
    .largeText {
        font-size: 24px;
    }
    .mediumText {
        font-size: 18px;
    }
    .smallText {
        font-size: 15px;
    }
    .tinyText {
        font-size: 13px;
    }
}

@media (min-width: 800px) {
    .largeText {
        font-size: 36px;
    }
    .mediumText {
        font-size: 24px;
    }
    .smallText {
        font-size: 16px;
    }
    .tinyText {
        font-size: 14px;
    }
}