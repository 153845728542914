@media screen and (max-width: 600px) {
    .headerText {
        width: 90%;
    }
}

@media screen and (min-width: 600px) {
    .headerText {
        width: 50%;
    }
}

.headerText {
    align-self: center;
    margin-bottom: 30px;
    color: #414141;
    line-height: 32px;
    font-size: 18px;
    letter-spacing: 1.1925px;
    font-family: "Open Sans Light Italic";
}

.werkplekHeader {
    font-size: 30px;
    margin-bottom: 15px;
    font-family: 'Arno Pro';
    color: #414141;
    line-height: 42px;
    letter-spacing: 2.38875px;
    text-align: center;
}

.werkplekText {
    line-height: 32px;
    font-size: 14px;
    font-family: 'Open Sans';
    letter-spacing: 0.9275px;
}