@import url('https://fonts.googleapis.com/css?family=Crimson+Text');

.collapsible {
	box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.12);
	color: black;
	mix-blend-mode: normal;
	padding: 0px 0px 18px 0px;
	border: none;
	text-align: left;
	outline: none;
	font-size: 15px;
	overflow: overlay;
}

.openBar {
	cursor: pointer;
}

.headerFont {
	font-family: 'Crimon Text';
	margin: auto;
	letter-spacing: 2.2px;
	font-size: 36px;
	margin-top: 36px;
	margin-bottom: 8px;
	color: #414141
}

.amount {
	margin-left: 10px;
	font-size: 20px;
	letter-spacing: 2.2px;

}

.collapsible:hover > .content{
	background: white;
    transition: max-height 0.5s;
}

.active, .collapsible:hover {
	background-color: white;
	transition: background 0.2s ease-in;
}

.content {
	transition: max-height 0.5s ease-out;
	color: black;

	padding: 0 18px;
	overflow: hidden;
	background-color: white;
}

.floatRight {
	float: right;
}

.upArrow {
	border-radius: 25%;
	cursor: pointer;
	margin-top: 24px;
	margin-right: 17px;
}
.upArrow:hover {
	background-color: #cce9ce;
}
.rotateArrow {
	transform: rotate(180deg);
}

.row {
	display: flex;
}

.col-12 {
	-ms-flex:0 0 100%;
	flex:0 0 100%;
	max-width:100%;
}

.col-6 {
	-ms-flex:0 0 50%;
	flex:0 0 50%;
	max-width:50%;
}

.startShopping {
	padding: 0.5em 0.7em 0.5em 0.7em;
	border-radius: 5px;
	text-decoration: none;
	cursor: pointer;
	background-color: #b9dcbf;
	color: white;
}
.startShopping:hover {
	background-color: #8ec197;
	color: white;
}
