.border-spaced {
	background-image: linear-gradient(to right, #979797 40%, rgba(255, 255, 255, 0) 0%);
	background-position: top;
	background-size: 12px 1px;
	background-repeat: repeat-x;
	height: 1px;
}

.left {
	float: left;
	padding: 40px 10px;
}
.left.border-spaced {
	background-image: linear-gradient(to bottom, #979797 40%, rgba(255, 255, 255, 0) 0%);
	background-position: left;
	background-size: 1px 12px;
	background-repeat: repeat-y;
	height: 2000px;
}
