.bottom {
	margin-bottom: 2.5em;
	position: absolute;
	bottom: 0;
}
.left {
	left: 0;
	float: left;
}
.right {
	right: 0;
	float: right;
}
