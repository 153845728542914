.imgBackground {
    background-color: rgba(65,65,65, 0);
	transition: background 0.2s;
}

.imgBackground:hover {
	background-color: rgba(126, 145, 141, 0.9);
}

.storyImage{
    align-self: center;
    margin-left: 25px;
    margin-right: 25px;
    width: 308px;
    height: 308px;
}

.storyImage:hover {
    cursor: pointer;
}

.storyImage img {
    width: 100%;
    height: 100%;
}

.storyImage .imgBackground {
    position: absolute;
    top: 0px;
    width: 308px;
    height: 308px;
    align-self: center;
}

.imgBackground p {
    transition: bottom 0.25s, opacity 0.25s;
    opacity: 0;
    font-family: Crimson Text;
    position: absolute;
    bottom: -25px;
    margin-left: 25px;
    color: white;
    font-size: 25px;
    width: 258px;
    letter-spacing: 2.2px;
}

.imgBackground:hover p {
    opacity: 1;
    bottom: 0px;
}
