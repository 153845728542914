.tabs > div:hover::before {
    width: 90%;
}

.tabs > div::before {
    height: 1px;
    width: 0%;
    background-color: black;
    content: '';
    bottom: 0;
    position: absolute;
    transition: width ease-out 200ms;
    align-self: center;
}

.tabs .seperator{
    transition: none;
    border: none;
}

.tabs > div:hover {
    cursor: pointer;
}