.productCardContainer {
    border: 0px solid #b6d7a8;
    padding: 20px;
    /* transition: border-width 200ms ease-in-out, padding 200ms ease-in-out; */
}

.productCardContainer:hover {
    border-width: 5px;
    padding: 15px;
    cursor: pointer;
}