@media screen and (max-width: 800px) {
    .container {
        flex-direction: column;
        margin-top: 20px;
    }
    .leftBlock {
        width: 100%;
    }
    .rightBlock {
        width: 100%;
    }
    .title {
        margin-top: 15px;
    }
    .deliveryContainer {
        margin-top: 40px;
    }
    .optionsContainer {
        margin-top: 40px;
    }
    .selectStyle {
        width: 100%;
    }
    .descriptionText {
        width: 90%;
    }
}


@media screen and (min-width: 800px) {
    .container {
        flex-direction: row;
        margin-top: 40px;
    }
    .leftBlock {
        width: 45%;
    }
    .rightBlock {
        width: 45%;
    }
    .title {
        margin-top: 0px;
    }
    .deliveryContainer {
        margin-top: 50px;
    }
    .optionsContainer {
        margin-top: 50px;
    }
    .selectStyle {
        width: 150px;
    }
    .buttonText {
        height: 40px;
    }
    .descriptionText {
        width: 70%;
    }
}

.container {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
}

.title {
    margin-bottom: 15pc;
}

.deliveryCheck {
    align-items: 'center';
    margin-top: 10px;
}


.selectStyle {
    height: 40px;
    font-family: 'Open Sans';
    margin-top: 5px;
    margin-right: 25px;
    padding-left: 5px;
    text-transform: capitalize;
}

.buttonsContainer {
    margin-top: 20px;
}

.buttonText {
    width: 100%;
    font-family: 'Open Sans';
    font-weight: bold;
}

.descriptionTitle {
    margin-bottom: 15px;
    font-family: 'Arno Pro',
}